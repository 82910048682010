import React from 'react';
import '../css/ThumbnailArea.css'; // Import corresponding CSS file


const urlPrefixes = {
    'CHT': {
        'image': "https://roomimg.stream.highwebmedia.com/riw/",
        'page': "https://chaturbate.com/"
    },
    'CM4': {
        'image': "https://snapshots.xcdnpro.com/thumbnails/", 
        'page': "https://www.cam4.com/"
    },
    'XHA': {
        'image': "https://img.strpst.com/thumbs/", 
        'page': "https://fr.xhamsterlive.com/"
    }
};

function ThumbnailArea({ data, currentPage, itemsPerPage }) {
    // Function to paginate data
    const paginate = (array, pageSize, pageNumber) => {
        return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    };

    // Function to create thumbnail elements
    const createThumbnails = () => {
        const paginatedData = paginate(data, itemsPerPage, currentPage);

        return paginatedData.map(user => {
            const timestamp = new Date().getTime();
            const sitePrefixes = urlPrefixes[user.site];
            const uniqueKey = `${user.site}-${user.username}`;
            let imageUrl, pageUrl;

            if (user.site === "XHA") {
                imageUrl = sitePrefixes.image + Math.floor(timestamp / 1000) + "/" + user.username.split("/")[1] + "_webp";
                pageUrl = sitePrefixes.page + user.username.split("/")[0];
            } else {
                imageUrl = sitePrefixes.image + user.username + ".jpg?t=" + timestamp;
                pageUrl = sitePrefixes.page + user.username;
            }

            const tooltipContent = `Username: ${user.username}\nRating: ${user.rating.toFixed(1)}\nAge: ${user.age.toFixed(1)}\nUsers: ${user.num_users.toFixed(1)}\nFollowers: ${user.num_followers.toFixed(1)}\nWhite: ${user.white}\nBlack: ${user.black}\nAsian: ${user.asian}\nIndian: ${user.indian}\nMiddle Eastern: ${user.middle_eastern}\nLatino Hispanic: ${user.latino_hispanic}`;

            return (
                <div key={uniqueKey} className="thumbnail">
                    <a href={pageUrl} target="_blank" rel="noopener noreferrer">
                        <div className="image-wrapper">
                            <img 
                                src={imageUrl} 
                                alt={user.username} 
                                title={tooltipContent} 
                                loading="lazy"  // Add lazy loading attribute
                            />
                        </div>
                    </a>
                </div>
            );
        });
    };

    return (
        <div id="thumbnail-area">
            {createThumbnails()}
        </div>
    );
}

export default ThumbnailArea;
