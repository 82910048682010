import React, { useState, useEffect } from 'react';
import '../css/PasswordModal.css'; // Import corresponding CSS file

function PasswordModal({ onSuccess }) {
    const [showModal, setShowModal] = useState(true);
    const accessDuration = 2 * 60 * 60 * 1000; // Duration for access granted (2 hours in milliseconds)

    // Function to check if the user agent is likely a crawler
    const checkIfRobot = () => {
        const userAgent = navigator.userAgent.toLowerCase();
        const crawlers = [
            'googlebot', 'bingbot', 'yandex', 'duckduckbot',
            'slurp', 'baiduspider', 'sogou', 'exabot', 'facebot', 'ia_archiver'
        ];
        return crawlers.some(crawler => userAgent.includes(crawler));
    };

    useEffect(() => {
        const isRobot = checkIfRobot();
        const accessData = JSON.parse(localStorage.getItem('accessData'));
        const hasAccess = accessData && new Date().getTime() - accessData.timestamp < accessDuration;

        if (isRobot || hasAccess) {
            setShowModal(false);
            if (hasAccess) {
                onSuccess();
            }
        } else {
            // Enable modal-specific styles only if the modal is shown
            document.body.style.overflow = 'hidden';
        }

        // Cleanup function to re-enable scrolling when the modal is not shown
        return () => {
            document.body.style.overflow = '';
        };
    }, [onSuccess, accessDuration]);

    const handleAccept = () => {
        const accessData = {
            timestamp: new Date().getTime()
        };
        localStorage.setItem('accessData', JSON.stringify(accessData));
        onSuccess();
    };

    const handleDecline = () => {
        window.location.href = 'https://www.google.com';
    };

    if (!showModal) return null;


    return (
        <div className="password-modal">
            <div className="modal-content">
    
                <div id="prompt-intro">
                    <h1>search<span className="highlighted-number">4</span>cam</h1>
                    <p>The first <b>real time</b> AI powered Cam Girl search engine.</p>
    
                    <p>
                    Discover the joy of personalized cam girls streaming! Our AI-driven platform curates content based on your interests, 
                        ensuring you only watch streams that captivate you. Dive into a world where your preferences lead the way.
                    </p>
                    <p>2000 models are scanned each minute by our AI engine. This enables to search for specific actions being executed at the moment.</p>

    
                    <h2>Age Verification</h2>
                    <p>
                        <span className="text-red">
                            <b>This website contains age-restricted materials including nudity and explicit depictions of sexual activity.<br/>
                            By entering, you affirm that you are at least 18 years of age or the age of majority in the jurisdiction you are accessing the website from and you consent to viewing sexually explicit content.</b>
                        </span>
                    </p>
    
                </div>
                <div className="button-container">
      <button className="accept-button" onClick={handleAccept}>Accept</button>
      <button className="decline-button" onClick={handleDecline}>Decline</button>
    </div>

            </div>
        </div>
    );
    
}

export default PasswordModal;

