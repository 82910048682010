import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Header from './Header';
import PasswordModal from './PasswordModal';
import '../css/whois.css'; 
import '../style.css';
import ThumbnailArea from './ThumbnailArea';

//const SERVER_URL="http://localhost:5000"
//const SERVER_URL="https://srv443878.hstgr.cloud"
const SERVER_URL="https://search4cam.com/api"


function Whois({rawData}) {
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [file, setFile] = useState(null);
    const [timeStampId, setTimeStampId] = useState(null);
    const [result, setResult] = useState(null);
    const [status, setStatus] = useState('idle'); // 'idle', "queued",'uploading', 'processing', 'complete', "noFace"
    const [processStatus, setProcessStatus] = useState('noData'); // 'noData','success','noFace' 
    const [uploadedImageName, setUploadedImageName] = useState(''); // New state for storing the uploaded image name
    const fileInputRef = useRef(null); // Create a reference to the file input
    const [progress, setProgress] = useState(0);



    const handlePasswordSuccess = () => {
        setIsModalOpen(false);
    };
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFile(file);
            uploadFile(file); // Start the upload process
        }
    };

    const uploadFile = async (selectedFile) => {
        const formData = new FormData();
        formData.append('file', selectedFile);
        setUploadedImageName(selectedFile.name); // Store the uploaded image name
        
        setProcessStatus("noData")
        setStatus('uploading');
        try {
            const response = await axios.post(SERVER_URL+'/upload-image', formData);
            setTimeStampId(response.data.timeStampId);
            setStatus('queued');
        } catch (error) {
            console.error('Error uploading file:', error);
            setStatus('idle');
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click(); // Programmatically click the hidden file input
    };


    const getImageUrl = () => {
        if (timeStampId && uploadedImageName) {
            const encodedImageName = encodeURIComponent(uploadedImageName);
            return SERVER_URL+`/images/${timeStampId}/${encodedImageName}`;
        }
        return '';
    };


    const displayResults = (data) => {
        const generateUrl = (item) => {
            switch (item.source) {
                case 'chaturbate':
                    return `https://chaturbate.com/${item.username}`;
                case 'cam4':
                    return `https://cam4.com/${item.username}`;
                case 'xHamster':
                    const usernamePart_xHam = item.username.split('/')[0]; // Extracts the first part of the username
                    return `https://xhamsterlive.com/${usernamePart_xHam}`;
                case 'babepdia':
                    const usernamePart_babe = item.username.replace("_"," "); // Extracts the first part of the username
                    return `https://www.babepedia.com/babe/${usernamePart_babe}`;
                default:
                    return '#'; // Default link or you can return null if you don't want a link
            }
        };
    
        const getReliabilityIndicator = (distance) => {
            if (distance < 0.025) {
                return { text: 'GOOD', color: 'darkgreen' };
            } else if (distance >= 0.025 && distance <= 0.035) {
                return { text: 'AVERAGE', color: 'orange' };
            } else {
                return { text: 'LOW', color: 'red' };
            }
        };
    
        return (
            <table>
                <thead>
                    <tr>
                        <th>Username</th>
                        <th style={{ textAlign: 'center' }}> Reliability </th> {/* Assuming you want the header centered as well */}
                    </tr>
                </thead>
                <tbody>
                    {data.result.map((item, index) => {
                        const reliability = getReliabilityIndicator(item.distance);
                        return (
                            <tr key={index}>
                                <td>
                                    <a href={generateUrl(item)} target="_blank" rel="noopener noreferrer">
                                        {item.username.split("/")[0]}
                                    </a>
                                </td>
                                <td style={{ color: reliability.color, textAlign: 'center' }}>
                                    {reliability.text}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    };
    

    const checkStatus = async () => {
        if (timeStampId) {
            try {
                const response = await axios.get(SERVER_URL+`/whois-result/${timeStampId}`);

                if (response.data.status === 'complete') {
                    setStatus('complete');
                    if (response.data.data.status==="ok") {
                        setResult(response.data.data);
                        setProcessStatus("success");
                    }
                    else {
                        setProcessStatus("noFace");
                    }
                }
                else
                    setStatus(response.data.status);
            } catch (error) {
                console.error('Error checking status:', error);
            }
        }
    };





    useEffect(() => {
        const interval = setInterval(() => {
            checkStatus();
        }, 3000); // Poll every 3 seconds

        if (status === 'complete' || status === 'idle' ) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [status, timeStampId]);


    useEffect(() => {
        let interval;

        if (status === 'uploading' || status === 'processing' || status === 'queued') {
            interval = setInterval(() => {
                setProgress(oldProgress => {
                    const newProgress = oldProgress >= 100 ? 0 : oldProgress + 100 / 30; // Increment every second
                    return newProgress;
                });
            }, 1000); // Update progress every second
        }

        if (status === 'complete') {
            setProgress(100);
        }

        if (status === 'idle') {
            setProgress(0);
        }

        return () => clearInterval(interval);
    }, [status]);

    return (
<div>
<div className="whois-container">
    <Header />
    {isModalOpen && <PasswordModal onSuccess={handlePasswordSuccess} />}
    <div className="whois-content-container">
        <section className="whois-block">
            <div className="whois-text-content">
                <header>
                    <h2 className='whois-title'>Who is she ?</h2>
                </header>
                <p>            
                    <input
                        type="file"
                        style={{ display: 'none' }} // Hide the file input
                        onChange={handleFileChange}
                        ref={fileInputRef}
                    />
                    <button onClick={handleButtonClick} disabled={status === 'uploading'}>Upload Image</button>
                </p>
                {
                    (status === 'complete' || status === 'noFace') ?
                    <img src={getImageUrl()} alt="Uploaded" className="whois-image"/> :
                    <img src="logo192.png" alt="Default" className="whois-image"/>
                }
            </div>
        </section>
        <section className="whois-block">
            <div className="whois-text-content">
                <header>
                    <h2 className='whois-title'>
                    {status === 'complete' && (
            processStatus === 'success' && result
                ? <div><p>Image successfully processed.</p></div>
                : processStatus === 'noFace'
                    ? <p>No face detected</p>
                    : <p>Processing, be patient...</p>
        )}
        {status === 'idle' && <p>Upload an image with a visible face</p>}
        {status !== 'complete' && status !== 'idle' && <p>Processing, be patient...</p>}

                        
                         </h2>
                </header>
                <div className="progress-bar-container">
                    <div className="progress-bar" style={{ width: `${progress}%` }}>
                    <div className="progress-text" style={{ width: `${progress}%` }}>
                        {status !== 'idle' && `${status}`}
                    </div>

                    </div>
                </div>

        {status === 'complete' && (
            processStatus === 'success' && result && <div>{displayResults(result)}</div>

        )}


 
        </div>

        </section>

    </div>




</div>


{status === 'complete' && 
            processStatus === 'success' &&
<div className="whois-thumbnail-container">
<h1 className="title">
    Closest matches online
</h1>
 
            <ThumbnailArea 
    data={result.thumbData} 
    currentPage={1} 
    itemsPerPage={10}
/>
</div>
}
</div>


    );
}



export default Whois;


