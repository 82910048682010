import React, { useState } from 'react';
import Header from './Header';
import ThumbnailArea from './ThumbnailArea';
import filterData, { specialFilters,feetFilter,highUserFilter }  from './filterData'; // Import filterData function
import { Link } from 'react-router-dom'; // Make sure to import Link from react-router-dom

import PasswordModal from './PasswordModal';
import '..//style.css';



const explicitFilter = {
    age: {young: true, mid: true, old: true},
    ethnicities: { any: true, white: false, black: false, asian: false, indian: false, middleEastern: false, latinoHispanic: false },
    exposition: { any: false, fa: false, br: false, be: false, pu: true, bu: false, fe: false },
    nudity: {notNude: true, noTop: true, noBottom: true},
    positions: {any: false, sp: true, dg: false, cu: false},
    sources: {CHT: true, CM4: true, XHA: true}};



const ethnicFilter = {
    age: {young: true, mid: true, old: true},
    ethnicities: { any: false, white: false, black: true, asian: false, indian: true, middleEastern: true, latinoHispanic: false },
    exposition: { any: false, fa: false, br: false, be: false, pu: true, bu: false, fe: false },
    nudity: {notNude: true, noTop: true, noBottom: true},
    positions: {any: false, sp: true, dg: false, cu: false},
    sources: {CHT: true, CM4: true, XHA: true}};
    
    


function Home({rawData}) {


    const explicitData = filterData(rawData, explicitFilter);
    const highUsers= highUserFilter(explicitData);
    const ethnicData = specialFilters(rawData, "ethnic");
    const lowUsers = specialFilters(explicitData, "lowUsers");
    const feetShow = feetFilter(rawData);


    //const filteredData = rawData;
    // Modal state
    const [isModalOpen, setIsModalOpen] = useState(true); // State to control modal visibility

    const handlePasswordSuccess = () => {
        setIsModalOpen(false); // Close modal on successful password entry
    };

    return (
        <div>
            <Header />
            {isModalOpen && <PasswordModal onSuccess={handlePasswordSuccess} />}
            <div className=".content-container">
                <h1 className="title">
                    <Link to="/search?preset=explicit">Top explicit cam</Link>
                </h1>
                <ThumbnailArea 
                    data={highUsers} 
                    currentPage={1} 
                    itemsPerPage={10}
                />
            </div>
            <div className=".content-container-no-sb">
                <h1 className="title">
                    <Link to="/search?preset=fewViewers">Few viewers</Link>
                </h1>
                <ThumbnailArea 
                    data={lowUsers} 
                    currentPage={1} 
                    itemsPerPage={10}
                />
            </div>
            <div className=".content-container-no-sb">
                <h1 className="title">
                    <Link to="/search?preset=ethnicBeauties">Ethnic beauties</Link>
                </h1>
                <ThumbnailArea 
                    data={ethnicData} 
                    currentPage={1} 
                    itemsPerPage={10}
                />
            </div>
            <div className=".content-container-no-sb">
                <h1 className="title">
                    <Link to="/search?preset=feet">Feet show</Link>
                </h1>
                <ThumbnailArea 
                    data={feetShow} 
                    currentPage={1} 
                    itemsPerPage={10}
                />
            </div>
        </div>
    );
}

export default Home;
