import React from 'react';
import { NavLink } from 'react-router-dom';
import '../css/Header.css'; 
import logo from './logo.png';

function Header() {
    return (
        <header>
            <div className="header-content">
                <div className="header-logo">
                    <img src={logo} alt="Logo" id="site-logo" />
                </div>
                <div className="header-text">
                    <h1>search<span className="highlighted-number">4</span>cam</h1>
                    <p>The first <b>real time</b> AI powered cam search engine.</p>
                </div>
            </div>
            <nav className="header-navigation">


                <NavLink to="/search" className={(navData) => (navData.isActive ? "active" : 'none')}><span>Search</span></NavLink>
                <NavLink to="/featured" className={(navData) => (navData.isActive ? "active" : 'none')}><span>Featured</span></NavLink>
                <NavLink to="/whois" className={(navData) => (navData.isActive ? "active" : 'none')}><span>Who is she?</span></NavLink>
                <NavLink to="/concept" className={(navData) => (navData.isActive ? "active" : 'none')}><span>Concept</span></NavLink>
                {/* Add other preset links as needed */}
            </nav>
        </header>
    );
}


export default Header;
