import { useState, useEffect } from 'react';

const useFetchData = (url, interval = 20000) => {
    const [data, setData] = useState([]);
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);

    // Function to fetch initial data (first 50 entries)
    const fetchInitialData = async () => {
        try {
            const response = await fetch(`${url}/api/get-data-start`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const initialData = await response.json();
            setData(initialData);
            // Placeholder for refreshThumbnails logic
        } catch (error) {
            console.error("Error fetching initial data:", error);
        }
    };

    // Function to fetch full data
    const fetchFullData = async () => {
        try {
            const response = await fetch(`${url}/api/get-data-full`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const fullData = await response.json();

            //debug
            const newData = [...data, ...fullData];
            const seen = new Set();
            const duplicates = [];
            for (const item of newData.reverse()) {
                const key = `${item.username}-${item.site}`;
                if (seen.has(key)) {
                    duplicates.push(item);
                } else {
                    seen.add(key);
                }
            }
    
            if (duplicates.length > 0) {
                console.log("Duplicates found:", duplicates);
            }

            //end debug

            setData(fullData);
        } catch (error) {
            console.error("Error fetching full data:", error);
        }
    };

    useEffect(() => {
        // Fetch initial data on component mount
        fetchInitialData();
    }, []); // Empty dependency array to ensure this runs only once

    useEffect(() => {
        if (!initialLoadComplete) {
            // Fetch full data immediately after initial data is loaded
            fetchFullData();
            setInitialLoadComplete(true);
        }

        // Set up interval to periodically fetch full data
        const intervalId = setInterval(fetchFullData, interval);

        return () => clearInterval(intervalId);
    }, [initialLoadComplete]); // Dependency on initialLoadComplete

    return data;
};

export default useFetchData;
