import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AdvancedSearch from './AdvancedSearch';
import Concept from './concept';
import Home from './Home';
import Whois from './Whois';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]); // Trigger the effect only when the pathname changes

    return null; // This component does not render anything
}

function AppRouter({ rawData }) {
    return (
        <Router>
            <ScrollToTop /> {/* Include ScrollToTop here inside the return */}
            <div>                
                {/* Route configuration */}
                <Routes>
                    <Route path="/" element={<AdvancedSearch rawData={rawData}/>} />
                    <Route path="/search" element={<AdvancedSearch rawData={rawData}/>} />
                    <Route path="/featured" element={<Home rawData={rawData}/>} />
                    <Route path="/concept" element={<Concept rawData={rawData}/>} />
                    <Route path="/whois" element={<Whois rawData={rawData}/>} />
                </Routes>
            </div>
        </Router>
    );
}

export default AppRouter;
