import React, { useState, useEffect } from 'react';
import '../css/pagination.css'; // Make sure to include the CSS file

function Pagination({ currentPage, setCurrentPage, itemsPerPage, totalItems }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    useEffect(() => {
        // Adjust the current page if it is out of range
        if (currentPage > totalPages) {
            setCurrentPage(1);
        }
    }, [totalPages, currentPage, setCurrentPage]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const renderPaginationButtons = () => {
        return [...Array(totalPages)].map((_, i) => (
            <button
                key={i}
                onClick={() => setCurrentPage(i + 1)}
                className={currentPage === i + 1 ? 'current-page' : ''}
            >
                {i + 1}
            </button>
        ));
    };

    const renderDropdown = () => {
        return (
            <select
                value={currentPage}
                onChange={(e) => setCurrentPage(Number(e.target.value))}
                className="pagination-dropdown"
            >
                {[...Array(totalPages)].map((_, i) => (
                    <option key={i} value={i + 1}>
                        Page {i + 1}
                    </option>
                ))}
            </select>
        );
    };

    return (
        <div id="pagination">
            {windowWidth < 960 ? renderDropdown() : renderPaginationButtons()}
        </div>
    );
}

export default Pagination;
