import React, { useState,useEffect } from 'react';
import ReactSwitch from 'react-switch';
import { useCheckboxGroup } from './tools';
import '../css/SearchPanel.css'; // Import your CSS file
import { useLocation } from 'react-router-dom';


// Default values for each category
const DEFAULT_VALUES = {
    exposition: { any: true, fa: false, br: false, be: false, pu: false, fe: false },
    nudity: { notNude: true, noTop: true, noBottom: true },
    positions: { any: true, sp: false, dg: false, cu: false },
    ethnicities: { any: true, white: false, black: false, asian: false, indian: false, middleEastern: false, latinoHispanic: false },
    age: { young: true, mid: true, old: true },
    sources: { CHT: true, CM4: true, XHA: true }
};

// Presets using the default values
const PRESETS = {
    explicit: {
        ...DEFAULT_VALUES,
        exposition: { ...DEFAULT_VALUES.exposition, any: false,pu : true },
        positions: { ...DEFAULT_VALUES.positions, any: false, sp :true },
    },
    closeUp: {
        ...DEFAULT_VALUES,
        positions: { ...DEFAULT_VALUES.positions, any:false,cu:true },
    },
    ethnicBeauties: {
        ...DEFAULT_VALUES,
        ethnicities: { ...DEFAULT_VALUES.ethnicities, any: false, white: false, black: true, asian: false, indian: true, middleEastern: true, latinoHispanic: false } ,
    }
};


function SearchPanel({ setExposition, setNudity, setPositions, setEthnicities, setAge, setSources }) {
    const location = useLocation();
    const [isPanelOpen, setIsPanelOpen] = useState(true);

    const parseUrlPresets = () => {
        const queryParams = new URLSearchParams(location.search);
        const preset = queryParams.get('preset');
        return PRESETS[preset] || { ...DEFAULT_VALUES };
    };
    // Effect to update state based on URL presets
    const presetValues = parseUrlPresets();

    useEffect(() => {
        const parseUrlPresets = () => {
            const queryParams = new URLSearchParams(location.search);
            const preset = queryParams.get('preset');
            return PRESETS[preset] || { ...DEFAULT_VALUES };
        };


        setExposition(presetValues.exposition);
        setNudity(presetValues.nudity);
        setPositions(presetValues.positions);
        setEthnicities(presetValues.ethnicities);
        setAge(presetValues.age);
        setSources(presetValues.sources);
    }, [location, setExposition, setNudity, setPositions, setEthnicities, setAge, setSources]);

    useEffect(() => {
        const handleResize = () => {
            setIsPanelOpen(window.innerWidth > 960);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        // Toggle the 'no-scroll' class on the body tag
        document.body.classList.toggle('no-scroll', isPanelOpen && window.innerWidth <= 960);

        return () => {
            // Remove the 'no-scroll' class when the component is unmounted
            document.body.classList.remove('no-scroll');
        };
    }, [isPanelOpen]);

    const togglePanel = () => {
        if (window.innerWidth <= 960) {
            setIsPanelOpen(prev => !prev);
        }
    };

    return (
        <div className="panel-container">
            <button className={`toggle-button ${isPanelOpen ? 'open' : ''}`} onClick={togglePanel}>
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
            </button>
            <div id="side-panel" className={isPanelOpen ? "open" : ""}>
            <ExpositionCheckBoxes setExposition={setExposition} currentPreset={presetValues}/>
                <PositionCheckboxes setPositions={setPositions} currentPreset={presetValues}/>
                <EthnicityCheckboxes setEthnicities={setEthnicities} currentPreset={presetValues}/>
                <AgeCheckboxes setAge={setAge} currentPreset={presetValues}/>
                <SourceCheckboxes setSources={setSources} currentPreset={presetValues}/>
            </div>
            <div className="content-area">
                {/* Your main content goes here */}
            </div>
        </div>
    );
}

function generateSwitches(handleToggleChange, switchStates, switchLabels, title) {
    return (
        <div className={"checkboxes-container"}>
            <p className="highlighted-title">{title}</p>
            {switchLabels.map(([label, stateKey]) => (
                <div className="toggle-container" key={label}>
                    <ReactSwitch
                        checked={switchStates[stateKey] !== undefined ? switchStates[stateKey] : false}
                        onChange={() => handleToggleChange(stateKey)(!switchStates[stateKey])}
                        className="react-switch"
                        onColor="#e65c00"
                        offColor="#ccc"
                    />
                    <label onClick={() => handleToggleChange(stateKey)(!switchStates[stateKey])}>
                        {label}
                    </label>
                </div>
            ))}
        </div>
    );
}

function generateSwitchesOld(handleToggleChange, switchStates, switchLabels,title) {


    return (
        <div className={"checkboxes-container"}>
            <p className="highlighted-title">{title}</p>
            {switchLabels.map(([label, stateKey]) => (
                <div className="toggle-container" key={label}>
                    <ReactSwitch
                        checked={switchStates[stateKey]}
                        onChange={() => handleToggleChange(stateKey)(!switchStates[stateKey])}
                        className="react-switch"
                        onColor="#e65c00" // Orange color for the switch when it's on
                        offColor="#ccc"   // You can set a color for when the switch is off

                    />
                    <label onClick={() => handleToggleChange(stateKey)(!switchStates[stateKey])}>
                        {label}
                    </label>
                </div>
            ))}
        </div>
    );
}


function ExpositionCheckBoxes({ setExposition, currentPreset }) {

    const [exposition, handleCheckboxChange] = useCheckboxGroup(
        currentPreset.exposition || {
        any: true,
        fa : false,
        br : false,
        be: false,
        pu: false,
        bu: false,
        fe: false
    }, newState => setExposition(newState));
    const handleToggleChange = (value) => {
        return (checked) => handleCheckboxChange({ target: { value, checked } });
    };

    return generateSwitches(handleToggleChange, exposition, [
        ["Any", "any"],
        ["Face", "fa"],
        ["Breast", "br"],
        ["Belly", "be"],
        ["Pussy", "pu"],
        ["Moon", "bu"],
        ["Feet", "fe"],
    ],"Seen last 2 mn [AND]");
    
}






function NudityCheckboxes({ setNudity, currentPreset }) {
    const [nudity, handleCheckboxChange] = useCheckboxGroup(
        currentPreset.nudity || {
        notNude: true,
        noTop: true,
        noBottom: true
    }, newState => setNudity(newState));

    const handleToggleChange = (value) => {
        return (checked) => handleCheckboxChange({ target: { value, checked } });
    };

    return generateSwitches(handleToggleChange, nudity, [
        ["Not Nude", "notNude"],
        ["No Top", "noTop"],
        ["No Bottom", "noBottom"],
    ],"Nudity");
    
}



function PositionCheckboxes({ setPositions, currentPreset }) {
    // Initialize the positions state with currentPreset.positions
    const [positions, handleCheckboxChange] = useCheckboxGroup(
        currentPreset.positions || {
            any: true,
            sp: false,
            dg: false
        },
        newState => setPositions(newState)
    );

    const handleToggleChange = (value) => {
        return (checked) => handleCheckboxChange({ target: { value, checked } });
    };

    return generateSwitches(handleToggleChange, positions, [
        ["Any", "any"],
        ["Spreading", "sp"],
        ["Doggy", "dg"],
    ], "Position last 2mn [OR]");
}



function EthnicityCheckboxes({ setEthnicities, currentPreset }) {
    const [ethnicities, handleCheckboxChange] = useCheckboxGroup(
        currentPreset.ethnicities ||{
        any: true,
        white: false,
        black: false,
        asian: false,
        indian: false,
        middleEastern: false,
        latinoHispanic: false
    }, newState => setEthnicities(newState));


    const handleToggleChange = (value) => {
        return (checked) => handleCheckboxChange({ target: { value, checked } });
    };

    return generateSwitches(handleToggleChange, ethnicities, [
        ["Any", "any"],
        ["White", "white"],
        ["Ebony", "black"],
        ["Asian", "asian"],
        ["Indian", "indian"],
        ["Middle-Eastern", "middleEastern"],
        ["Latino-Hispanic", "latinoHispanic"],
    ],"Ethnicity [OR]");
    
}

function AgeCheckboxes({setAge, currentPreset}) {
    const [age, handleCheckboxChange] = useCheckboxGroup(
        currentPreset.age || {
        young: true,
        mid: true,
        old: true
    }, newState => setAge(newState));

    const handleToggleChange = (value) => {
        return (checked) => handleCheckboxChange({ target: { value, checked } });
    };

    return generateSwitches(handleToggleChange, age, [
        ["Young", "young"],
        ["Less Young", "mid"],
        ["Even Less Young", "old"],
    ],"Age [OR]");
    
}

function SourceCheckboxes({setSources, currentPreset}) {
    const [sources, handleCheckboxChange] = useCheckboxGroup(
        currentPreset.sources || {
        CHT: true,
        CM4: true,
        XHA: true
    }, newState => setSources(newState));

    const handleToggleChange = (value) => {
        return (checked) => handleCheckboxChange({ target: { value, checked } });
    };

    return generateSwitches(handleToggleChange, sources, [
        ["Chaturbate", "CHT"],
        ["xHamster Live", "XHA"],
        ["CAM4", "CM4"],
    ],"Source [OR]");
    
}

export default SearchPanel;