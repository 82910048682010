import React, { useState } from 'react';
import Header from './Header';
import SearchPanel from './SearchPanel';
import ThumbnailArea from './ThumbnailArea';
import Pagination from './Pagination';
import filterData from './filterData'; // Import filterData function
import PasswordModal from './PasswordModal';
import '..//style.css';

function AdvancedSearch({rawData}) {


    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);

    // States for each filter
    const [exposition, setExposition] = useState({ any: true, fa: true, br: true, be: true, pu: true, bu: true, fe: true });
    const [nudity, setNudity] = useState({ notNude: true, noTop: true, noBottom: true });
    const [positions, setPositions] = useState({ any: true, sp: false, dg: false, cu: false });
    const [ethnicities, setEthnicities] = useState({ any: true, white: false, black: false, asian: false, indian: false, middleEastern: false, latinoHispanic: false });
    const [age, setAge] = useState({ young: true, mid: true, old: true });
    const [sources, setSources] = useState({ CHT: true, CM4: true, XHA: true });

    // Applying filters to the data
    const filters = {
        exposition,
        nudity,
        positions,
        ethnicities,
        age,
        sources
    };
    const filteredData = filterData(rawData, filters);

    // Modal state
    const [isModalOpen, setIsModalOpen] = useState(true); // State to control modal visibility

    const handlePasswordSuccess = () => {
        setIsModalOpen(false); // Close modal on successful password entry
    };

    return (
        <div>
            <Header />
            {isModalOpen && <PasswordModal onSuccess={handlePasswordSuccess} />}
            <div className="content-container">
                <SearchPanel
                    exposition={exposition}
                    nudity={nudity}
                    positions={positions}
                    ethnicities={ethnicities}
                    age={age}
                    sources={sources}
                    setExposition={setExposition}
                    setNudity={setNudity}
                    setPositions={setPositions}
                    setEthnicities={setEthnicities}
                    setAge={setAge}
                    setSources={setSources}
                />
                <ThumbnailArea 
                    data={filteredData} 
                    currentPage={currentPage} 
                    itemsPerPage={50}
                />
                <Pagination 
                    currentPage={currentPage} 
                    setCurrentPage={setCurrentPage} 
                    totalItems={filteredData.length} 
                    itemsPerPage={50} 
                />
            </div>
        </div>
    );
}

export default AdvancedSearch;
