import React from 'react';
import AppRouter from './modules/AppRouter'; // Import the AppRouter
import useFetchData from './modules/FetchData';



function App() {
    const rawData = useFetchData('https://search4cam.com/');
    //const rawData = useFetchData('https://srv443878.hstgr.cloud/');
    return <AppRouter rawData={rawData} />;
}

export default App;
