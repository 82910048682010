import React, { useState } from 'react';



// Custom hook for managing checkbox groups with an 'any' option
export function useCheckboxGroup(initialState, updateParentState) {
    const [state, setState] = useState(initialState);

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;

        if (value === 'any') {
            // When 'any' is changed, update the state accordingly
            const newState = Object.keys(state).reduce((acc, key) => {
                acc[key] = key === 'any' ? checked : !checked;
                return acc;
            }, {});

            setState(newState);
            updateParentState(newState); // Notify the parent component
        } else {
            // When any other checkbox is changed, update the state accordingly
            setState(prevState => {
                const newState = { ...prevState, [value]: checked, any: false };

                // Check if all specific checkboxes are unchecked
                const allUnchecked = Object.entries(newState)
                    .filter(([key]) => key !== 'any')
                    .every(([, value]) => !value);

                if (allUnchecked) {
                    newState.any = true;
                }

                updateParentState(newState); // Notify the parent component
                return newState;
            });
        }
    };

    return [state, handleCheckboxChange];
}

/*
// Custom hook for managing checkbox groups with an 'any' option
export function useCheckboxGroup(initialState, updateParentState) {
    const [state, setState] = useState(initialState);

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;

        if (value === 'any') {
            const newState = Object.keys(state).reduce((acc, key) => {
                acc[key] = key === 'any' ? checked : !checked;
                return acc;
            }, {});

            setState(newState);
        } else {
            setState(prevState => {
                const newState = { ...prevState, [value]: checked, any: false };

                // Check if all specific checkboxes are unchecked
                const allUnchecked = Object.entries(newState)
                    .filter(([key]) => key !== 'any')
                    .every(([, value]) => !value);

                if (allUnchecked) {
                    newState.any = true;
                }

                return newState;
            });
        }
    };

    return [state, handleCheckboxChange];
}
*/

/*
export function useCheckboxGroup(initialState, updateParentState) {
    const [state, setState] = useState(initialState);

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;

        setState(prevState => {
            const newState = { ...prevState, [value]: checked };

            if (value === 'any') {
                Object.keys(newState).forEach(key => {
                    newState[key] = key === 'any' ? checked : !checked;
                });
            } else {
                const allUnchecked = Object.entries(newState)
                    .filter(([key]) => key !== 'any')
                    .every(([, val]) => !val);
                if (allUnchecked) {
                    newState.any = true;
                } else {
                    newState.any = false;
                }
            }

            // Update parent state
            updateParentState(newState);

            return newState;
        });
    };

    return [state, handleCheckboxChange];
}

*/