const filterData = (data, filters) => {
    return data.filter(user => {
        // Position Filter
        if (!filters.exposition.any) {
            if (filters.exposition.fa && user.fa < 1000) return false;
            if (filters.exposition.br && user.br < 1000) return false;
            if (filters.exposition.be && user.be < 500) return false;
            if (filters.exposition.pu && user.pu < 500) return false;
            if (filters.exposition.bu && user.bu < 4000) return false;
            if (filters.exposition.fe && user.fe < 2000 ) return false;
            
        }


        //if (!(user.num_users>=0 && user.num_users >=500)) return false; 

        // Nudity (pLevel) Filter
        if (!filters.nudity.notNude && user.pLevel <= 1) return false;
        if (!filters.nudity.noTop && user.pLevel === 2) return false;
        if (!filters.nudity.noBottom && user.pLevel === 3) return false;

        // Position Filter
        if (!filters.positions.any) {
            if (!(filters.positions.sp && user.Sp !== -1) 
            && !(filters.positions.dg && user.Dg !== -1)
            && !(filters.positions.cu &&  user.pu >=4000)
            ) {
                return false;
            }
        }

        // Ethnicity Filter
        if (!filters.ethnicities.any) {
            const userEthnicities = [
                { type: 'white', value: user.white },
                { type: 'black', value: user.black },
                { type: 'asian', value: user.asian },
                { type: 'indian', value: user.indian },
                { type: 'middleEastern', value: user.middle_eastern },
                { type: 'latinoHispanic', value: user.latino_hispanic }
            ];
        
            // Find the maximum value among the ethnicities
            const maxValue = Math.max(...userEthnicities.map(e => e.value));
        
            // Filter ethnicities that are within 2 points of the max value
            const relevantEthnicities = userEthnicities
                .filter(e => maxValue - e.value <= 1)
                .map(e => e.type);
        
            // Check if any of the relevant ethnicities are active in the filters
            const ethnicityMatch = relevantEthnicities.some(ethnicity => filters.ethnicities[ethnicity]);
            if (!ethnicityMatch) return false;
        }
        
        // Age Filter
        const ageMatch = (filters.age.young && user.age <= 30) ||
                         (filters.age.mid && user.age > 30 && user.age <= 40) ||
                         (filters.age.old && user.age > 40);
        if (!ageMatch) return false;

        // Source Filter
        if (!filters.sources[user.site]) return false;

        return true; // User passes all filters
    }).sort((a, b) => b.rating - a.rating); // Sort in descending order by rating
};



// New function added as a named export
const specialFilters = (data,filter) => {
    return data.filter(user => {

        if (filter==="ethnic")
            if (user.white>0 || user.black<5) return false;



        if (filter==="lowUsers")
            if (user.num_users<0 || user.num_users>10) return false;


        if (filter==="highUsers")
            if (user.num_users<100 ) return false;


        return true;

    }).sort((a, b) => b.rating - a.rating); // Sort in descending order by rating


};

const highUserFilter = (data) => {
    return data.filter(user => {

        return true;

    }).sort((a, b) => b.num_users - a.num_users); // Sort in descending order by rating


};


const feetFilter = (data) => {
    return data.filter(user => {

        return true;

    }).sort((a, b) => b.fe - a.fe); // Sort in descending order by rating


};



export { specialFilters,feetFilter,highUserFilter };
export default filterData;
