import React, { useState } from 'react';
import Header from './Header';
import PasswordModal from './PasswordModal';
import '../css/Concept.css'; // Import your CSS file
import '..//style.css';

function Concept() {
    const [isModalOpen, setIsModalOpen] = useState(true);

    const handlePasswordSuccess = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="concept-container">
            <Header />
            {isModalOpen && <PasswordModal onSuccess={handlePasswordSuccess} />}
            <div className="concept-content-container">
                <section className="concept-block">
                    <img src="Tailored_video_stream.jpg" alt="Description" className="concept-image" />
                    <div className="text-content">
                        <header>
                            <h2>AI-Powered Viewing Revolution</h2>
                        </header>
                        <p>Experience the cutting-edge of cam girls streaming with our AI technology. Our smart filters let you choose the action you want to see, 
                            transforming how you interact with models. It's not just streaming; it's a viewing revolution tailored to you.</p>
                    </div>
                </section>
                <section className="concept-block">
                    <img src="Ai_Powered_revolution.jpg" alt="Description" className="concept-image" />
                    <div className="text-content">
                        <header>
                            <h2>Tailored Streams at Your Fingertips</h2>
                        </header>
                        <p>Discover the joy of personalized cam girls streaming! Our AI-driven platform curates content based on your interests, 
                        ensuring you only watch streams that captivate you. Dive into a world where your preferences lead the way.</p>
                    </div>
                </section>
                <section className="concept-block">
                    <img src="beyond_popular.jpg" alt="Description" className="concept-image" />
                    <div className="text-content">
                        <header>
                            <h2>Explore Beyond the Popular</h2>
                        </header>
                        <p>Venture into the uncharted territories of live streaming. Our innovative sorting system brings you streams beyond the mainstream, 
                        allowing you to uncover less-known but incredibly engaging girls that resonates with your unique tastes.</p>
                    </div>
                </section>
                <section className="concept-block">
                    <img src="constantly_evolving.jpg" alt="Description" className="concept-image" />
                    <div className="text-content">
                        <header>
                            <h2>Constantly Evolving for You</h2>
                        </header>
                        <p>We're committed to enhancing your viewing experience. Regular updates and feature improvements on our platform ensure 
                        that your live streaming journey is always fresh, 
                        exciting, and aligned with your evolving preferences. Stay with us for a constantly improving adventure.</p>
                    </div>
                </section>
                {/* Add more blocks as needed */}
            </div>
        </div>
    );
}

export default Concept;
